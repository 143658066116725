import {
  CompanyToPlanSource,
  CompanyToPlanType,
  getCompanies1,
  getCountries,
} from "@/generated/api/users";
import { itemsPerPage } from "@/common/components/pagination/pagination";
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";

type Company = {
  id: number;
  number: string;
  country: string;
  name: string;
  plan: {
    source: CompanyToPlanSource;
    type: CompanyToPlanType;
    expiresAt?: DateTime;
  };
};

const companiesQuery = ({
  country,
  page,
  plan,
  planSource,
  planValid,
  search,
}: {
  country: string;
  page: string;
  plan: CompanyToPlanType;
  planSource: CompanyToPlanSource;
  planValid?: boolean;
  search: string;
}) =>
  queryOptions({
    queryKey: ["companies", search, plan, planSource, country, planValid, page],
    queryFn: () =>
      getCompanies1({
        size: itemsPerPage.oneHundred.toString(),
        page,
        country,
        planSource,
        planValid: planValid !== undefined ? planValid.toString() : "",
        plan,
        search,
        sortCol: "NAME",
      }),
    staleTime: Infinity,
  });

const authorizedCountriesQuery = () =>
  queryOptions({
    queryKey: ["authorized-countries"],
    queryFn: () => getCountries(),
    select: (countries) => countries.filter((country) => country.isAuthorized),
    staleTime: Infinity,
  });

export { companiesQuery, authorizedCountriesQuery };
export type { Company };
