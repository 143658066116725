import { getUser1 } from "@/generated/api/users";
import { queryOptions } from "@tanstack/react-query";

const userQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["user", id],
    queryFn: () => getUser1(id),
  });

export { userQuery };
