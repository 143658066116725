import React from "react";
import {
  ClientError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  ServerError,
  TooManyCompaniesError,
  UnauthorizedError,
} from "@/api/errors";
import { Spinner } from "@/common/components/spinner/spinner";
import { logger } from "@/common/services/logger";
import { t } from "@lingui/macro";
import { useNavigate } from "react-router";

const Info = ({ children }: React.PropsWithChildren<unknown>) => (
  <div role="status" className="flex flex-grow items-center justify-center">
    <p className="text-sm text-can-stormy-sky">{children}</p>
  </div>
);

const Failed = ({ error }: { error: unknown }) => {
  const navigate = useNavigate();
  let message = t`Něco se pokazilo`;
  logger.error(error);

  // TODO is there a better way to check for invalid refresh token?
  // 403 error is returned, but it's kinda out of hand (comes from the auth0 SDK)
  // there is an `error` property on auth0 error
  React.useEffect(() => {
    if (
      error instanceof Error &&
      // eslint-disable-next-line lingui/no-unlocalized-strings
      error.message.includes("refresh token")
    ) {
      navigate("/logout");
    }
  }, [error, navigate]);

  if (!(error instanceof Error)) {
    return <Info>{message}</Info>;
  }

  switch (error.name) {
    case ForbiddenError.getName():
    case UnauthorizedError.getName():
    case NetworkError.getName():
    case NotFoundError.getName():
    case ClientError.getName():
    case TooManyCompaniesError.getName():
    case ServerError.getName(): {
      if (error.message) {
        message = error.message;
      }
    }
  }

  return <Info>{message}</Info>;
};

const NoData = ({ children }: React.PropsWithChildren) => (
  <Info>
    {children ?? t`Pro zvolené časové období nejsou k dispozici žádná data.`}
  </Info>
);

const Pending = () => (
  <Info>
    <Spinner />
  </Info>
);

export { Failed, NoData, Pending };
