import { Spinner } from "@/common/components/spinner/spinner";
import { ApprovalStatus } from "@/common/services/user";
import { Trans } from "@lingui/macro";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useUserStateMutation } from "../../pending-users/pending-users.api";

type Fields = {
  status: ApprovalStatus | "";
};

const UserActions = ({
  userCompanyRelationId,
}: {
  userCompanyRelationId: number;
}) => {
  const form = useForm<Fields>({
    defaultValues: {
      status: "",
    },
  });
  const queryClient = useQueryClient();
  const userStateMutation = useUserStateMutation();

  const handleSubmit = (data: Fields) => {
    if (data.status === "") {
      return;
    }
    userStateMutation.mutate(
      { userCompanyRelationId, state: data.status },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <form className="flex items-center gap-x-2">
      <select
        disabled={userStateMutation.status === "pending"}
        className="mt-2 w-[110px] rounded-lg border"
        {...form.register("status", {
          onChange: () => {
            form.handleSubmit(handleSubmit)();
          },
        })}
      >
        <option value="">
          <Trans>Akce</Trans>
        </option>
        <option value="APPROVED">
          <Trans>Schválit</Trans>
        </option>
        <option value="BLOCKED">
          <Trans>Neschválit</Trans>
        </option>
      </select>
      {userStateMutation.status === "pending" ? (
        <Spinner className="!h-4 !w-4" withHint={false} />
      ) : null}
    </form>
  );
};

export { UserActions };
