import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  RowData,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";
import { pendingUsersQuery } from "./pending-users.api";
import { DateTime } from "luxon";
import { ApprovalStatus } from "@/common/services/user";
import React from "react";
import { Trans, t } from "@lingui/macro";
import { Spinner } from "@/common/components/spinner/spinner";
import { useHasPermission } from "@/common/acl/guard/guard";
import { Link, Outlet, useSearchParams } from "react-router-dom";
import { Metadata } from "@/common/components/metadata/metadata";
import { Table } from "../components/table/table";
import { UserActions } from "../components/user-actions/user-actions";
import { Filter } from "../components/table/filter/filter";
import { AutocompleteString } from "@/types";
import { NoData } from "@/common/components/info/info";
import { Spacer } from "@/common/components/spacer/spacer";
import {
  Pagination,
  itemsPerPage,
} from "@/common/components/pagination/pagination";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "text" | "select";
    label: () => string;
  }
}

type User = {
  createdAt: DateTime;
  id: number;
  email: string;
  company: {
    name: string;
    country: string;
    number: string;
    id: string;
  };
  approvalStatus: ApprovalStatus;
};

const columnHelper = createColumnHelper<User>();

const path = "pending-users";

// @ts-expect-error I don't care about the type here
const fallback = [];

const Users = () => {
  const { params, handleParamChange } = usePendingUsersParams();
  const pendingUsers = useQuery({
    placeholderData: keepPreviousData,
    ...pendingUsersQuery({
      page: params.page,
      search: params.search,
    }),
  });
  const hasCompanyApprovePermission = useHasPermission("users:company:approve");
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("id", {
        header: () => "ID",
      }),

      columnHelper.accessor("email", {
        header: () => t`E-mail`,
        meta: {
          label: () => t`E-mail`,
          filterKey: "search",
          filterVariant: "text",
        },
        cell: (info) => {
          return (
            <SearchParamsLink
              className="text-can-forest-teal"
              to={info.row.getValue<User["id"]>("id").toString()}
            >
              {info.getValue()}
            </SearchParamsLink>
          );
        },
      }),

      columnHelper.accessor("createdAt", {
        header: () => t`Datum registrace`,
        meta: {
          label: () => t`Datum registrace`,
        },
        cell: (info) => info.getValue().toLocaleString(DateTime.DATE_SHORT),
        enableColumnFilter: false,
      }),

      columnHelper.accessor("company", {
        header: () => t`Společnost`,
        meta: {
          label: () => t`Společnost`,
          filterVariant: "text",
          filterKey: "search",
        },
        cell: (info) => (
          <Link
            className="text-can-forest-teal"
            to={"company/" + info.getValue().id}
          >
            {info.getValue().country +
              " " +
              info.getValue().name +
              " / " +
              info.getValue().number}
          </Link>
        ),
      }),
      ...(hasCompanyApprovePermission
        ? [
            columnHelper.display({
              id: "actions",
              cell: ({ row }) => (
                <UserActions userCompanyRelationId={row.getValue("id")} />
              ),
            }),
          ]
        : []),
    ],
    [hasCompanyApprovePermission],
  );

  const table = useReactTable({
    // @ts-expect-error I don't care about the type here
    data: pendingUsers.data?.users ?? fallback,
    columns,
    filterFns: {},
    manualFiltering: true,
    manualPagination: true,
    state: {
      columnVisibility: {
        id: false,
      },
    },
    getCoreRowModel: getCoreRowModel(),
  });

  if (pendingUsers.status === "pending" && pendingUsers.isInitialLoading) {
    return (
      <>
        <Metadata title={t`Čekající na schválení`} />
        <Spinner className="mt-4" />
      </>
    );
  }

  return (
    <>
      <Metadata title={t`Čekající na schválení`} />
      <label className="mx-4 flex items-baseline gap-x-4 text-can-slate-blue-gray">
        <Trans>Hledat e-mail, jméno/IČO společnosti</Trans>
        <Filter
          onFilterChange={handleParamChange}
          value={params.search}
          column={table.getColumn("email")!}
        />
      </label>
      <Spacer className="h-4" />
      <Table>
        <Table.Head table={table} />
        {pendingUsers.status === "success" &&
        pendingUsers.data.users.length > 0 ? (
          <Table.Body table={table} />
        ) : null}
      </Table>
      {pendingUsers.data ? (
        <>
          <Spacer className="h-5" />
          <Pagination
            getter={() => parseInt(params.page, 10)}
            setter={(page) => handleParamChange("page", page.toString())}
            pageSize={itemsPerPage.oneHundred}
            total={pendingUsers.data.totalSize}
          />
        </>
      ) : null}
      {pendingUsers.status === "success" &&
      pendingUsers.data.users.length === 0 ? (
        <div className="mt-4">
          <NoData>
            <Trans>Nebyly nalezeny žádné záznamy</Trans>
          </NoData>
        </div>
      ) : null}
      {pendingUsers.fetchStatus === "fetching" &&
      !pendingUsers.isInitialLoading ? (
        <Spinner withHint={false} />
      ) : null}
      <Outlet />
    </>
  );
};

const usePendingUsersParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = {
    page: searchParams.get("page") ?? "1",
    search: searchParams.get("search") ?? "",
  };

  const handleParamChange = (
    key: AutocompleteString<"search">,
    value: string,
  ) => {
    setSearchParams((c) => {
      c.set(key, value);
      return c;
    });
  };

  return { params, handleParamChange };
};

export { Users, path };
