import { Settings } from "luxon";

let locale: string | undefined = undefined;

const setLocale = (nextLocale: string) => {
  locale = nextLocale;
  Settings.defaultLocale = nextLocale;
};

const currencyFormatter = (currency: string) =>
  new Intl.NumberFormat(locale, {
    currency,
    style: "currency",
  });

const numberFormatter = (options: Intl.NumberFormatOptions = {}) =>
  new Intl.NumberFormat(locale, options);

const percentageFormatter = new Intl.NumberFormat(locale, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
});

export { currencyFormatter, numberFormatter, percentageFormatter, setLocale };
