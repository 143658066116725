const permissions = [
  "news:report:manage",
  "users:company:approve",
  "users:*:list",
  "users:company:delete",
  "users:company:plan",
  "users:user:block",
  "users:user:delete",
  "users:user:reset",
  "country:*",
] as const;

// ugly hack to support dynamic country: permissions
// TODO reasonable to get these from elsewhere?
type Permission =
  | Exclude<(typeof permissions)[number], "country:*">
  | `country:${string}`;

export { permissions };
export type { Permission };
