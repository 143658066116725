import { sortString } from "@/common/utils/array";
import {
  CompanyPlanPutRequest,
  getCompany1,
  getCompanyUsers,
  updateCompanyPlan,
} from "@/generated/api/users";
import { queryOptions, useMutation } from "@tanstack/react-query";
import { DateTime } from "luxon";

const companyQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["company", id],
    queryFn: () => getCompany1(id),
    select: ({ planExpiresAt, ...data }) => ({
      ...data,
      planExpiresAt: planExpiresAt
        ? DateTime.fromFormat(planExpiresAt, "yyyy-MM-dd").toLocaleString(
            DateTime.DATE_FULL,
          )
        : "-",
    }),
  });

const companyUsersQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["company", id, "users"],
    queryFn: () => getCompanyUsers(id),
    select: (users) =>
      users.toSorted((a, b) =>
        sortString(a.userEmail ?? "", b.userEmail ?? ""),
      ),
  });

const useUpdateCompanySubscriptionPlanMutation = () =>
  useMutation({
    mutationFn: (payload: { companyId: number; plan: CompanyPlanPutRequest }) =>
      updateCompanyPlan(payload.companyId, payload.plan),
  });

type CompanyUser = Awaited<ReturnType<typeof getCompanyUsers>>[number];

export {
  companyQuery,
  companyUsersQuery,
  useUpdateCompanySubscriptionPlanMutation,
};
export type { CompanyUser };
