import { getUsers } from "@/generated/api/users";
import { queryOptions } from "@tanstack/react-query";

const usersQuery = ({ page, search }: { page: string; search: string }) =>
  queryOptions({
    queryKey: ["users", page, search],
    queryFn: () =>
      getUsers({
        page,
        size: "100",
        search,
        sortCol: "NAME",
      }),
  });

export { usersQuery };
