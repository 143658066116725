import * as PurchasePrices from "./purchase-prices/purchase-prices";
import { RouteObject } from "react-router-dom";

const routes: RouteObject[] = [
  {
    path: PurchasePrices.path,
    element: (
      <PurchasePrices.Restoration>
        {(params) => (
          <PurchasePrices.PurchasePricesParams params={params}>
            <PurchasePrices.PurchasePrices />
          </PurchasePrices.PurchasePricesParams>
        )}
      </PurchasePrices.Restoration>
    ),
  },
];

export { routes };
