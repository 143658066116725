import { PermissionGuard } from "@/common/acl/guard/guard";
import { Trans } from "@lingui/macro";
import { NavLink, Outlet } from "react-router-dom";
import { path as pendingUsersPath } from "../../pending-users/pending-users";
import { path as companiesPath } from "../../companies/companies";
import { path as usersPath } from "../../users/users";

const Layout = () => {
  const handleSentryTest = () => {
    throw new Error(Date.now().toString());
  };

  return (
    <div>
      <nav className="sticky top-0 bg-can-forest-teal font-bold text-white">
        <ul className="flex justify-center gap-4">
          <PermissionGuard permission="news:report:manage">
            <li className="p-4 has-[.is-active]:bg-can-mystic-aqua">
              <NavLink
                to="editor"
                className={({ isActive }) => (isActive ? "is-active" : "")}
              >
                <Trans>Editor zpráv</Trans>
              </NavLink>
            </li>
          </PermissionGuard>
          <PermissionGuard permission="users:*:list">
            <li className="p-4 has-[.is-active]:bg-can-mystic-aqua">
              <NavLink
                to={usersPath}
                className={({ isActive }) => (isActive ? "is-active" : "")}
              >
                <Trans>Správa uživatelů</Trans>
              </NavLink>
            </li>
          </PermissionGuard>
          <PermissionGuard permission="users:*:list">
            <li className="p-4 has-[.is-active]:bg-can-mystic-aqua">
              <NavLink
                to={companiesPath}
                className={({ isActive }) => (isActive ? "is-active" : "")}
              >
                <Trans>Správa společností</Trans>
              </NavLink>
            </li>
          </PermissionGuard>
          <PermissionGuard permission="users:*:list">
            <li className="p-4 has-[.is-active]:bg-can-mystic-aqua">
              <NavLink
                to={pendingUsersPath}
                className={({ isActive }) => (isActive ? "is-active" : "")}
              >
                <Trans>Čekající na schválení</Trans>
              </NavLink>
            </li>
          </PermissionGuard>
          <li className="p-4 has-[.is-active]:bg-can-mystic-aqua">
            <NavLink to="/logout">
              <Trans>Odhlásit se</Trans>
            </NavLink>
          </li>
          <li>
            <button
              className="p-4 text-can-mystic-aqua"
              onClick={handleSentryTest}
              // eslint-disable-next-line lingui/no-unlocalized-strings
            >
              Sentry test
            </button>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  );
};

export { Layout };
